import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractMatFormFieldControl, arrayOfNotNull, controlValues, formatMinutes, mapNotNull } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, combineLatest, merge, Observable, of as observableOf } from "rxjs";
import { distinctUntilChanged, first, map, mapTo, shareReplay, skip, switchMap, takeUntil } from "rxjs/operators";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatAutocomplete, MatAutocompleteModule } from "@angular/material/autocomplete";
import { FindRouteDto, RouteChainId, RouteEndpoint, RouteId } from "apina-frontend";
import { filterRoutesOrChains } from "../../domain/routes";
import { brandedRouteId } from "../../domain/id-parsing";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { AsyncPipe } from "@angular/common";

const MAX_FILTER_RESULTS = 100;

/**
 * Allow selecting route or route chain.
 */
@Component({
    selector: "app-select-route",
    templateUrl: "./select-route.component.html",
    providers: [
        {provide: MatFormFieldControl, useExisting: SelectRouteComponent},
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
    host: {
        '(focus)': 'focus()'
    },
})
export class SelectRouteComponent extends AbstractMatFormFieldControl<RouteId> implements AfterViewInit {

    private static nextId = 0;

    readonly control = new FormControl<string | unknown>("", {nonNullable: true});

    readonly filteredRoutes$: Observable<FilterResults>;

    private readonly selectedRouteId = new BehaviorSubject<RouteId | null>(null);

    private readonly _defaultRouteIds = new BehaviorSubject<RouteId[]>([]);

    private readonly _allowedRouteIds = new BehaviorSubject<RouteId[] | null>(null);

    @ViewChild('inputField') inputField!: ElementRef;

    @ViewChild('routeAutoComplete') routeAutoComplete?: MatAutocomplete;

    @Output()
    readonly routeSelected = new EventEmitter<RouteId>();

    @Input()
    set defaultRouteIds(ids: RouteId[]) {
        this._defaultRouteIds.next(ids);
    }

    @Input()
    set allowedRouteIds(ids: RouteId[] | null) {
        this._allowedRouteIds.next(ids);
    }

    /**
     * Is the autocomplete currently open?
     *
     * We'd like to use routeAutoComplete.isOpen, but it still reports "open" when closing events arrive,
     * so we need to track state manually.
     */
    private _autoCompleteOpen = false;

    private readonly routes$: Observable<readonly RouteInfo[]>;

    get value(): RouteId | null {
        return this.selectedRouteId.value;
    }

    set value(id: RouteId | null) {
        if (id !== this.selectedRouteId.value) {
            this.selectedRouteId.next(id);
            this.updateRouteName();
            this.stateChanges.next();
        }
    }

    constructor(routeEndpoint: RouteEndpoint) {
        super("app-select-route", SelectRouteComponent.nextId++);

        const allRoutes$ = routeEndpoint.findRoutesSortedByPilotageAmount().pipe(map(rs => rs.map(r => new RouteInfo(r))), shareReplay(1));

        // Make sure that the results are presented in the same order that they would be shown in default order
        this.routes$ = combineLatest([allRoutes$, this._defaultRouteIds]).pipe(map(([rs, ids]) => orderByIds(rs, ids)), shareReplay(1));

        this.filteredRoutes$ = combineLatest([controlValues(this.control), this.routes$, this._allowedRouteIds]).pipe(
            switchMap(([v, routes, allowedRouteIds]) => {
                if (typeof v !== "string" || v === "")
                    return this.defaultSelections();

                if (allowedRouteIds != null)
                    routes = routes.filter(it => allowedRouteIds.includes(it.id));

                return observableOf(new FilterResults(filterRoutesOrChains(v, routes), MAX_FILTER_RESULTS));
            }));

        this.stateChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            if (!this.focused)
                this.updateRouteName();
        });
    }

    ngAfterViewInit(): void {
        merge(this.routeAutoComplete!.opened.pipe(mapTo(true)), this.routeAutoComplete!.closed.pipe(mapTo(false))).pipe(takeUntil(this.componentDestroyed$)).subscribe(open => {
            this._autoCompleteOpen = open;
            this.stateChanges.next();
        });
    }

    protected override get hasCustomFocus(): boolean {
        return this._autoCompleteOpen;
    }

    protected override onDisabled(disabled: boolean): void {
        if (disabled)
            this.control.disable();
        else
            this.control.enable();
    }

    focus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.focus();
    }

    fieldReceivedFocus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.select();
    }

    selectRoute(route: RouteInfo | null): void {
        if (route != null) {
            this.value = route.id;
            this.control.setValue(route.name);
            this.routeSelected.emit(route.id);
        } else {
            this.value = null;
            this.control.setValue("");
        }
    }

    private updateRouteName(): void {
        const id = this.value;
        if (id != null) {
            this.routes$.pipe(first()).subscribe(routes => {
                const v = routes.find(it => it.id === id);
                this.control.setValue(v?.name ?? "");
            });
        } else {
            this.control.setValue("");
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        // BehaviorSubject broadcasts the initial value, we skip it to avoid sending invalid change event
        this.selectedRouteId.pipe(skip(1), distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(fn);
    }

    private defaultSelections(): Observable<FilterResults> {
        return combineLatest([this.routes$, this._defaultRouteIds]).pipe(
            map(([routes, defaultRouteIds]) =>
                new FilterResults(mapNotNull(defaultRouteIds, id => routes.find(it => it.id === id)))));
    }
}

/**
 * Returns array of given items but so that elements given by `ids` are first (in
 * the order of of ids-array) and the other items are after that, in original order.
 */
function orderByIds(items: readonly RouteInfo[], ids: readonly number[]): RouteInfo[] {
    const idsSet = new Set(ids);
    const prefixElementsById: Map<number, RouteInfo> = new Map();
    for (const item of items)
        if (idsSet.has(item.id))
            prefixElementsById.set(item.id, item);

    const result: RouteInfo[] = [];
    for (const id of ids) {
        const item = prefixElementsById.get(id);
        if (item !== undefined)
            result.push(item);
    }

    for (const item of items)
        if (!prefixElementsById.has(item.id))
            result.push(item);

    return result;
}

export interface DiscriminatedRouteId {
    readonly type: 'route';
    readonly id: RouteId;
}

export interface DiscriminatedRouteChainId {
    readonly type: 'chain';
    readonly id: RouteChainId;
}

export type RouteOrChainId = DiscriminatedRouteId | DiscriminatedRouteChainId;

function discriminatedRouteId(id: RouteId): DiscriminatedRouteId {
    return {"type": "route", id};
}

function discriminatedRouteChainId(id: RouteChainId): DiscriminatedRouteChainId {
    return {"type": "chain", id};
}

export function equalRouteOrChainId(lhs: RouteOrChainId | null, rhs: RouteOrChainId | null): boolean {
    return (lhs == null || rhs == null) ? (rhs == null && rhs == null) : (lhs.type === rhs.type && lhs.id === rhs.id);
}

export function chainOrRouteId(routeChainId: RouteChainId | null, routeId: RouteId | null): RouteOrChainId | null {
    return routeChainId != null ? discriminatedRouteChainId(routeChainId) : routeId != null ? discriminatedRouteId(routeId) : null;
}

class FilterResults {
    readonly rows: ReadonlyArray<RouteInfo>;
    readonly extraNote: string | null;

    constructor(results: ReadonlyArray<RouteInfo>, limit: number = results.length) {
        this.rows = results.slice(0, limit);

        if (results.length > limit) {
            this.extraNote = `+ ${results.length - limit} muuta`;
        } else {
            this.extraNote = null;
        }
    }
}

class RouteInfo {
    readonly id: RouteId;
    readonly name: string;
    readonly description: string | null;
    readonly startCode: string;
    readonly startName: string;
    readonly codes: string;
    readonly endCode: string;
    readonly endName: string;
    readonly details: string;

    constructor(dto: FindRouteDto) {
        this.id = brandedRouteId(dto.id);
        this.name = dto.name;
        this.description = dto.description;
        this.startCode = dto.startCode;
        this.startName = dto.startName;
        this.codes = dto.codes.join("-");
        this.endCode = dto.endCode;
        this.endName = dto.endName;

        this.details = arrayOfNotNull(
            `${dto.miles} nm`,
            dto.defaultDuration != null ? formatMinutes(dto.defaultDuration) : null
        ).join(", ");
    }
}
