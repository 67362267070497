<input #inputField matInput [formControl]="control" [placeholder]="placeholder" [matAutocomplete]="vesselAutoComplete" (focus)="fieldReceivedFocus()">
<mat-autocomplete #vesselAutoComplete="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="selectVessel($event.option.value)">
    @if (filteredVessels$ | async; as filteredVessels) {
        @if (!required && control.value === '' && value != null) {
            <mat-option [value]="null">-</mat-option>
        }
        @for (vessel of filteredVessels.vessels; track vessel.id) {
            <mat-option [value]="vessel" class="h-[60px]">
                <div class="flex justify-between items-baseline">
                    <span>{{ vessel.name }}</span>
                    <span class="text-de-emphasized text-xs text-right">{{ vessel.callSign || 'n/a' }} - {{ vessel.mmsi || 'n/a' }} - {{ vessel.imo || 'n/a' }}</span>
                </div>
                @if (showVesselClass) {
                    <div class="text-de-emphasized text-xs text-right">{{ vessel.vesselClass || 'n/a' }}</div>
                }
            </mat-option>
        }
        @if (allowNewVessel && filteredVessels.vessels != null && filteredVessels.vessels.length === 0) {
            <mat-option [value]="'new-vessel'">
                Luo uusi alus
            </mat-option>
        }
    }
</mat-autocomplete>
